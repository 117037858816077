export default ({blok}) => {
	return (
		<section className="banner-block prada">
			<div className="section-full">
				<div className="banner-block-cont">
					{blok.image_link ? (
						<a href={blok.image_link.url} className="banner-block-img">
							{(blok['mobile_background'] && blok['mobile_background']['filename']) ?
								<img src={blok['mobile_background'].filename} alt={blok['mobile_background'].alt}
								     className="banner-block-img-mobile"/> : null}
							<img src={blok.background.filename} alt={blok.background.alt}/>
						</a>
					) : (
						<div className="banner-block-img">
							{(blok['mobile_background'] && blok['mobile_background']['filename']) ?
								<img src={blok['mobile_background'].filename} alt={blok['mobile_background'].alt}
								     className="banner-block-img-mobile"/> : null}
							<img src={blok.background.filename} alt={blok.background.alt}/>
						</div>
					)}
					<h2 className="banner-block-title">
						<img src={blok.logo.filename} alt={blok.logo.alt}/>
					</h2>
				</div>
				<div className="banner-block-info">
					<h2 className="banner-block-info-title">{blok.title}</h2>
					<p className="banner-block-info-text">{blok.description}</p>
					<div className="banner-block-info-btns">
						{blok.links.map((link) => (
							<a key={link._uid} href={link.link.url}
							   className="banner-block-info-btn">
								{link.title}
							</a>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
