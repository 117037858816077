import Loader from "@/Components/LoaderMin.jsx";

export default ({ blok }) => {
    if (!blok.BackgroundMobile) return <Loader />;

    return (
        <section>
            <div className="banner">
                <div className="banner-bgc">
                    {blok.BackgroundMobile && blok.BackgroundMobile.filename && (
                        <img
                            fetchpriority="high"
                            loading="eager"
                            decoding="async"
                            className="banner-bgc-mobile"
                            src={blok.BackgroundMobile.filename}
                            alt={blok.background.alt}
                            width="100%"
                            height="auto"
                        />
                    )}
                    <img
                        fetchpriority="high"
                        loading="eager"
                        decoding="async"
                        src={blok.background.filename}
                        alt={blok.background.alt}
                        width="100%"
                        height="auto"
                    />
                </div>
                <div className="banner-info">
                    <h1 className="banner-info-title">{blok.title}</h1>
                    <p className="banner-info-text">{blok.description}</p>
                    <div className="banner-info-btns">
                        {blok.links.map((link) => (
                            <a key={link._uid} href={link.link.url} className="banner-info-btn">
                                {link.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
