import {storyblokEditable, useStoryblok} from '@storyblok/react'
import { useEffect, useState } from 'react'
import Author from '@/Components/Author'
import Content from '@/Components/Content'
import Date from '@/Components/Date'
import Image from '@/Components/Image'
import Story from "@/Components/Blog/Story";

export default ({ blok, tags }) => {
  const className = 'blog';
	const [stories,setStories] = useState([]);
	const [needRequest,setNeedRequest] = useState(true);
	const getStories = (uuids) => {
		fetch(`/storiesByUuid/${uuids}`)
			.then(res => res.json())
			.then(
				(result) => {
					setStories(result.story.stories);
					setNeedRequest(false)
				}
			)
	}
	if (needRequest) getStories(blok.more_stories.toString())

  return (
    <main className={className + ' pb-12'} {...storyblokEditable(blok)}>
      <section>
	      <div className="section-full">
		      <a href="/stories" className="stories-back-link">Back to Stories</a>
	      </div>
        <div className="stories-section mx-auto">
	        <div className="stories-tags">{tags.toString().replaceAll(',',' + ')}</div>
          <h2 className="stories-title">{blok.title}</h2>

          <div className="blog-time-to-read">
            {blok.read_time && (
              <>
                {blok.read_time}
                <span/>
              </>
            )}
            <Date date={blok.date} />
            {blok.author && (
              <>
                <span/>
                <Author author={blok.author} />
              </>
            )}
          </div>
        </div>
      </section>

      {blok.featured_image && (
        <section className="mt-12">
          <div className="section-full">
	          <Image image={blok.featured_image} />
          </div>
        </section>
      )}

      <section className="mt-12">
        <div className="stories-section mx-auto leading-relaxed space-y-6">
          {<Content content={blok.content} />}
        </div>
      </section>

      {blok.closing_image && (
        <section className="mt-12">
          <div className="section-full">
            <Image image={blok.closing_image} />
          </div>
        </section>
      )}

      {blok.footer && (
        <section className="mt-12">
          <div className="stories-section mx-auto text-sm text-neutral-500 space-y-2">
            <Content content={blok.footer} />
          </div>
        </section>
      )}
	    <section className="related-stories">
		    <h2 className="related-title">More Stories</h2>
		    <div className="blogs-grid">
			    {stories.map(s => (
				    <Story key={s.id} story={s} />
			    ))}
		    </div>
	    </section>
    </main>
  )
}
